export const ACCOUNT = "auth";

export const ADMIN = "admin";
export const HOME = "home";

export const LINK_EXPIRED = "link-expired";

export const LOGIN = "login";

export const FORGOT_PASSWORD = "forgot-password";

export const RESET_PASSWORD = "reset-password";

export const REPORTS = "reports";

export const USER = "user";

export const USER_LIST = "list";

export const USER_DETAIL = "detail";

export const FEEDBACK = "feedback";

export const FEEDBACK_DETAIL = "detail";

export const SETTINGS = "settings";

export const ADMIN_PROFILE = "profile";

export const CHANGE_PASSWORD = "change-password";

export const EDIT_PROFILE = "edit-profile";

export const NOTIFICATION = "notification";

export const NOTIFICATION_LIST = "list";

export const ADD_NOTIFICATION = "add";

export const EDIT_NOTIFICATION = "list";
export const VIEW = "view";
export const CONTENT_MANAGEMENT = "content-management";

export const DASHBOARD = "dashboard";

export const INTEREST = "interest";
export const WRITING = "essay";

export const LIST = "list";
export const ADD = "add";
export const EDIT = "edit";
export const DETAILS = "detail";

export const LANGUAGE = "language";
export const LANGUAGE_LIST = "list";

export const TEACHER = "teacher";

export const EBOOK = "ebook";
export const REVIEW = "review";
export const PUBLICATIONS = "publications";
export const INTEREST_LIST_ROUTE = "/interest/list";
export const PAYMENT = "payments";
export const SUB_ADMIN = "sub-admin";
export const DISCUSSION = "discussion";
export const SESSION = "session";
export const FAQ = "faq";
export const NEW_BOOK = "new-book";
export const NEW_TEACHER = "new-teacher";
export const NEW_USERS = "new-users";
export const TOP_BOOKS = "top-book";
export const NEW_SESSION = "new-session";
export const CONTACT_US = "contact-us";

export const CHANNELS = "channels";
export const ADMIN_NOTIFICATIONS = "admin-notifications";
export const SUBSCRIPTIONS = "subscriptions";
export const SUBSCRIBERS = "subscribers";
export const ANNOUNCEMENT = "announcement"
export const REWARDS = "rewards";
export const MARKETPLACE = "marketplace";

export const SCHOOL = "school";

export const EDUCATOR = "educator";

export const STUDENT = "student";